import { Injectable } from '@angular/core';

import { catchError, map, mergeMap, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { userActions } from '../actions/user.actions';
import { HttpService } from '../../service';

@Injectable()
export class UserEffects {
    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.getUserDataAction),
            mergeMap(() =>
                this.httpService.getUserData.pipe(
                    map((user) => userActions.getUserDataSuccessAction({ user })),
                    catchError(() => of(userActions.getUserDataErrorAction())),
                ),
            ),
        );
    });

    // getUserSuccess$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(userActions.getUserDataSuccessAction),
    //         map((data) => fromUserPermissions.getUserPermissionsAction({ role: data.user.role })),
    //     );
    // });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
