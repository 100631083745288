import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../models';

export enum UserActionTypes {
    GET_USER_DATA = '[User] Get User data',
    GET_USER_DATA_SUCCESS_ACTION = '[User] Get User data success',
    GET_USER_DATA_ERROR_ACTION = '[User] Get User data error',
    CLEAR_STATE = '[User] Clear State',
}

const getUserDataAction = createAction(UserActionTypes.GET_USER_DATA);
const getUserDataSuccessAction = createAction(UserActionTypes.GET_USER_DATA_SUCCESS_ACTION, props<{ user: UserModel }>());
const getUserDataErrorAction = createAction(UserActionTypes.GET_USER_DATA_ERROR_ACTION);
const clearStateAction = createAction(UserActionTypes.CLEAR_STATE);

export const userActions = {
    getUserDataAction,
    getUserDataSuccessAction,
    getUserDataErrorAction,
    clearStateAction,
};
