import { inject } from '@angular/core';
import { CanMatchFn, Route, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs';
import { PermissionsFacade } from '../store/facade/permissions.facade';
import { UserFacade } from '@app/modules/user/store';
import { GuiFacade } from '@app/gui';

export const CanEnterContextMatch: CanMatchFn = (route: Route) => {
    const router = inject(Router);
    const permissionsFacade = inject(PermissionsFacade);
    const guiFacade = inject(GuiFacade);
    const userFacade = inject(UserFacade);
    const contextName = route.data?.['context'] ?? null;
    const actionName = route.data?.['action'] ?? null;
    const redirectOnReject = route.data?.['redirectOnReject'] ?? false;

    // #FIXME: For some reason canMatch is executed before canActivate - this breaks routing logic. We need to also check if user is loaded.
    return userFacade.state$.pipe(
        tap((state) => {
            if (state.executed === false) {
                userFacade.getUserDataAction();
            }
        }),
        filter((state) => state.executed),
        mergeMap(() =>
            permissionsFacade.permissions$.pipe(
                map((permissions) => {
                    const context = permissions.context.find((context) => context.name === contextName);
                    const hasAccess = context?.actions.includes(actionName) ?? false;

                    if (hasAccess === false) {
                        if (redirectOnReject) {
                            guiFacade.showErrorMessage('Brak dostępu');
                            return router.createUrlTree(['/s/dashboard']);
                        }
                        return false;
                    }

                    return true;
                }),
            ),
        ),
    );
};
