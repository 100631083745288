import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PermissionsEffects } from './store/effects/permissions.effects';
import { permissionsReducer } from './store/reducers/permissions.reducer';
import { PERMISSIONS_FEATURE_KEY } from './keys';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([PermissionsEffects]), StoreModule.forFeature(PERMISSIONS_FEATURE_KEY, permissionsReducer)],
})
export class PermissionsModule {}
