import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/auth';
import { Observable, map, mergeMap } from 'rxjs';
import { ApiResponse } from '@app/shared/models/api-response.model';
import { UserModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {}

    get getUserData(): Observable<UserModel> {
        return this.httpClient.get<ApiResponse<UserModel>>(`user`).pipe(map((res) => res.data));
    }

    reauthenticateUser(): Observable<UserModel> {
        return this.authService.refresh().pipe(
            mergeMap(() => {
                return this.getUserData.pipe(map((response) => response));
            }),
        );
    }
}
