import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { GuiFacade } from '@app/gui';
import { PermissionsFacade } from '../store/facade/permissions.facade';

export const CanEnterContextGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const permissionsFacade = inject(PermissionsFacade);
    const guiFacade = inject(GuiFacade);
    const contextName: string = route.data['context'];
    const actionName: string = route.data['action'];

    return permissionsFacade.permissions$.pipe(
        map((permissions) => {
            const context = permissions.context.find((context) => context.name === contextName);
            const hasAccess = context?.actions.includes(actionName) ?? false;

            if (hasAccess === false) {
                guiFacade.showErrorMessage('Brak dostępu');
                return router.createUrlTree(['/s/dashboard']);
            }

            return true;
        }),
    );
};
