import { Injectable } from '@angular/core';
import { filter, map, switchMap, take } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PermissionsActions } from '../actions/permissions.actions';
import { HttpService } from '../services/http.service';
import { userActions } from '../../../user/store/actions/user.actions';
import { selectUserRole } from '../../../user/store/selectors/user.selector';

@Injectable({
    providedIn: 'root',
})
export class PermissionsEffects {
    getUserPermissions$ = createEffect(() => {
        return this.action$.pipe(
            ofType(userActions.getUserDataSuccessAction),
            concatLatestFrom(() => this.store.select(selectUserRole).pipe(take(1))),
            map(([, role]) => role),
            filter((role): role is string => role !== undefined),
            switchMap((role) => {
                return this.httpService.getUserPermissions(role).pipe(map((payload) => PermissionsActions.getUserPermissionsSuccess({ payload })));
            }),
        );
    });

    constructor(
        private store: Store,
        private action$: Actions,
        private httpService: HttpService,
    ) {}
}
