import { createReducer, on } from '@ngrx/store';
import { PermissionsActions } from '../actions/permissions.actions';
import { Permissions } from '../../models/permissions';

export interface PermissionsState {
    permissions: Permissions | null;
}

export const initialState: PermissionsState = {
    permissions: null,
};

export const permissionsReducer = createReducer(
    initialState,
    on(
        PermissionsActions.getUserPermissionsSuccess,
        (state, action): PermissionsState => ({
            ...state,
            permissions: action.payload,
        }),
    ),
    on(
        PermissionsActions.clear,
        (state): PermissionsState => ({
            ...state,
            ...initialState,
        }),
    ),
);
