import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PermissionsActions } from '../actions/permissions.actions';
import { selectPermissions } from '../selectors/permissions.selector';
import { filter } from 'rxjs';
import { Permissions } from '../../models/permissions';

@Injectable({
    providedIn: 'root',
})
export class PermissionsFacade {
    permissions$ = this.store.select(selectPermissions).pipe(filter((permissions): permissions is Permissions => permissions !== null));
    permissionsNullable$ = this.store.select(selectPermissions);

    constructor(private store: Store) {}

    getUserPermissions(role: string): void {
        this.store.dispatch(PermissionsActions.getUserPermissions({ role }));
    }
}
