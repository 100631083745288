import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsFacade } from '@app/modules/permissions/store/facade/permissions.facade';
import { Subscription, tap } from 'rxjs';

@Directive({
    selector: '[appHasPermission]',
    standalone: true,
})
export class HasPermissionDirective implements OnInit, OnDestroy {
    @Input('appHasPermission') public permission!: [string, string];

    private subscriptions$ = new Subscription();

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private permissionsFacade: PermissionsFacade,
    ) {}

    ngOnInit(): void {
        this.subscriptions$.add(
            this.permissionsFacade.permissions$
                .pipe(
                    tap((permissions) => {
                        const [contextName, actionName] = this.permission;
                        const hasAccess =
                            permissions.context.findIndex((context) => context.name === contextName && context.actions.includes(actionName)) !== -1;
                        if (hasAccess) {
                            this.viewContainerRef.createEmbeddedView(this.templateRef);
                        } else {
                            this.viewContainerRef.clear();
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }
}
