import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Permissions } from '../../models/permissions';

export const PermissionsActions = createActionGroup({
    source: 'Permissions',
    events: {
        'Get User Permissions': props<{ role: string }>(),
        'Get User Permissions Success': props<{ payload: Permissions }>(),

        Clear: emptyProps(),
    },
});
