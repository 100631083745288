export enum Roles {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_CALL_CENTER = 'ROLE_CALL_CENTER',
    ROLE_DISPATCHER = 'ROLE_DISPATCHER',
    ROLE_FLEET_MANAGER = 'ROLE_FLEET_MANAGER',
    ROLE_PARTNER_ACCOUNTANT = 'ROLE_PARTNER_ACCOUNTANT',

    ROLE_DRIVER = 'ROLE_DRIVER',
    ROLE_DRIVER_ACCOUNTANT = 'ROLE_DRIVER_ACCOUNTANT',

    ROLE_PARTNER_EMPLOYEE = 'ROLE_PARTNER_EMPLOYEE',

    ROLE_USER = 'ROLE_USER',
}
