import { Permissions } from '../models/permissions';
import { Context, Actions, Roles } from '../enums';

export const partnerEmployeePermissions: Permissions = {
    role: Roles.ROLE_PARTNER_EMPLOYEE,
    context: [
        {
            name: Context.DASHBOARD,
            actions: [Actions.VIEW],
        },
        {
            name: Context.PLANNING,
            actions: [],
        },
        {
            name: Context.BOOKING,
            actions: [Actions.VIEW, Actions.CREATE, Actions.UPDATE],
        },
        {
            name: Context.PARCEL,
            actions: [],
        },
        {
            name: Context.DRIVERS,
            actions: [],
        },
        {
            name: Context.VEHICLES,
            actions: [],
        },
        {
            name: Context.USERS,
            actions: [],
        },
        {
            name: Context.PARTNERS,
            actions: [],
        },
        {
            name: Context.IMPORT,
            actions: [],
        },
        {
            name: Context.REPORT_INVOICE,
            actions: [],
        },
        {
            name: Context.REPORT_DRIVERS_COURSES,
            actions: [],
        },
        {
            name: Context.REPORT_PARTNER,
            actions: [],
        },
        {
            name: Context.REPORT_DRIVERS_CASH,
            actions: [],
        },
        {
            name: Context.REPORT_SCHEDULE,
            actions: [],
        },
    ],
};
