import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { userActions } from '../actions/user.actions';
import * as userSelectors from '../selectors/user.selector';
import { take, tap } from 'rxjs';
import { UserState } from '../reducers/user.reducer';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    private store = inject(Store);

    readonly state$ = this.store.select(userSelectors.selectState);
    readonly user$ = this.store.select(userSelectors.selectUser);
    readonly executed$ = this.store.select(userSelectors.selectExecuted);

    getUserDataAction(): void {
        this.store.dispatch(userActions.getUserDataAction());
    }

    clearState(): void {
        this.store.dispatch(userActions.clearStateAction());
    }

    get state(): UserState {
        let state: UserState = {
            executed: false,
            user: null,
        };

        this.state$
            .pipe(
                take(1),
                tap((result) => (state = result)),
            )
            .subscribe();

        return state;
    }
}
