import { createReducer, on } from '@ngrx/store';
import { UserModel } from '../../models';

import { userActions } from '../actions/user.actions';

export interface UserState {
    executed: boolean;
    user: UserModel | null;
}

export const initialState: UserState = {
    executed: false,
    user: null,
};

export const userReducer = createReducer(
    initialState,
    on(
        userActions.getUserDataSuccessAction,
        (state, action): UserState => ({
            ...state,
            executed: true,
            user: action.user,
        }),
    ),
    on(
        userActions.getUserDataErrorAction,
        (state): UserState => ({
            ...state,
            user: null,
        }),
    ),
    on(
        userActions.clearStateAction,
        (state): UserState => ({
            ...state,
            executed: false,
            user: null,
        }),
    ),
);
