import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Permissions } from '../../models/permissions';

import {
    adminPermissions,
    callCenterPermissions,
    dispatcherPermissions,
    driverAccountantPermissions,
    driverPermissions,
    fleetManagerPermissions,
    partnerAccountantPermissions,
    partnerEmployeePermissions,
} from '../../configs';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    getUserPermissions(role: string): Observable<Permissions> {
        const permissions: Permissions[] = [
            { ...adminPermissions },
            { ...callCenterPermissions },
            { ...driverPermissions },
            { ...partnerEmployeePermissions },
            { ...partnerAccountantPermissions },
            { ...driverAccountantPermissions },
            { ...dispatcherPermissions },
            { ...fleetManagerPermissions },
        ];

        const userPermision: Permissions[] = permissions.filter((permission) => permission.role === role);

        return from(userPermision);
    }
}
