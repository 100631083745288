export enum Context {
    DASHBOARD = 'dashboard',
    PLANNING = 'planning',
    BOOKING = 'booking',
    PARCEL = 'parcel',
    DRIVERS = 'drivers',
    VEHICLES = 'vehicles',
    USERS = 'users',
    PARTNERS = 'partners',
    IMPORT = 'import',

    REPORT_INVOICE = 'report-invoice',
    REPORT_DRIVERS_COURSES = 'report-drivers-courses',
    REPORT_PARTNER = 'report-partner',
    REPORT_DRIVERS_CASH = 'report-drivers-cash',
    REPORT_SCHEDULE = 'report-schedule',
    PERSON_LIST = 'person-list',
    PARTNER_DEPARTURE = 'partner-departure',
}
